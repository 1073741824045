import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardYearlyPriceProps = {
  contract: BackofficeContractDescription
}

export const ContractCardOptions: FunctionComponent<ContractCardYearlyPriceProps> = memo(function ContractCardOptions({
  contract,
}) {
  const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
  const options = checkDefinedAndNotNull(latestVersion.dimensions.allOptions)

  return (
    <DataRow>
      <Text variant="body2">Options</Text>
      <span>
        {options.map((option, index) => {
          return (
            <Text variant="body2" key={index}>
              {option}
            </Text>
          )
        })}
      </span>
    </DataRow>
  )
})
