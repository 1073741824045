import styled from '@emotion/styled'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text, spacing } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'
import { InteractiveNotesBlock } from '../notes/interactive-notes-block'

export type ContractCardNotesRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardNotesRow: FunctionComponent<ContractCardNotesRowsProps> = memo(function ContractCardNotesRows({
  contract,
}) {
  return (
    <DataRow>
      <Text variant="body2">Notes</Text>
      <NotesWrapper>
        <InteractiveNotesBlock targetId={contract.subscriptionId} />
      </NotesWrapper>
    </DataRow>
  )
})

const NotesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[40]};
  margin: ${spacing[40]};
`
