import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { formatCompanyIdNumber } from '@orus.eu/company-id-number'
import { allowedCompanyIdNumberTypesPerZone } from '@orus.eu/dimensions'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Text, useLanguage } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardSirenRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardSirenRow: FunctionComponent<ContractCardSirenRowsProps> = memo(function ContractCardSirenRows({
  contract,
}) {
  const language = useLanguage()
  const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
  const companyIdNumber = checkDefinedAndNotNull(
    latestVersion.dimensions.companyIdNumber ?? ({ type: 'later' } as const),
  )
  const operatingZone = checkDefinedAndNotNull(latestVersion.dimensions.operatingZone)
  return (
    <DataRow>
      <Text variant="body2">
        {[...allowedCompanyIdNumberTypesPerZone[operatingZone]]
          .filter((type) => type !== 'later')
          .join('/')
          .toUpperCase()}
      </Text>
      <Text variant="body2">{formatCompanyIdNumber(companyIdNumber, language)}</Text>
    </DataRow>
  )
})
