import styled from '@emotion/styled'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { DownloadButtonWithHref, Text, spacing } from '@orus.eu/pharaoh'
import { useParams } from '@tanstack/react-router'
import { memo, type FunctionComponent } from 'react'
import { getAnyGeneralTermsDocumentDownloadLink } from '../../../lib/download-links'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardGenericDocumentsRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardGenericDocumentsRow: FunctionComponent<ContractCardGenericDocumentsRowsProps> = memo(
  function ContractCardGenericDocumentsRows({ contract }) {
    const { subscriptionId } = contract
    const { type: userType } = usePermissions()
    const { organization } = useParams({ strict: false })
    const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
    return (
      <DataRow>
        <Text variant="body2">Documents génériques</Text>
        <ButtonGroup>
          {checkDefinedAndNotNull(latestVersion.dimensions.generalTerms).map((file) => (
            <DownloadButtonWithHref
              key={file.fileName}
              variant="secondary"
              size="small"
              href={getAnyGeneralTermsDocumentDownloadLink({
                subscriptionId,
                file,
                organization: organization ?? '',
                isPartner: userType === 'partner' && !!organization,
              })}
              fileName={file.fileName}
            />
          ))}
        </ButtonGroup>
      </DataRow>
    )
  },
)

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${spacing[40]};
  margin: ${spacing[40]};
`
