import { css } from '@emotion/react'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, Text, useAsyncCallback, useEnqueueToast } from '@orus.eu/pharaoh'
import { type FunctionComponent, memo, useState } from 'react'
import { trpcReact } from '../../../client'
import { DataRow } from '../../molecules/backoffice-data-card'

export const ContractCardSendDocumentsRow: FunctionComponent<{ contract: BackofficeContractDescription }> = memo(
  function ContractCardSendDocumentsRow({ contract }) {
    const [sendingEmail, setSendingEmail] = useState(false)

    const { subscriptionId } = contract
    const { enqueueToast } = useEnqueueToast()
    const sendCustomerDocumentsEmailMutation = trpcReact.contracts.sendCustomerDocumentsEmail.useMutation()

    const downloadDocuments = useAsyncCallback(async () => {
      setSendingEmail(true)
      await sendCustomerDocumentsEmailMutation.mutateAsync(subscriptionId)
      enqueueToast('Documents envoyés par mail', { variant: 'success' })
      setSendingEmail(false)
    }, [enqueueToast, sendCustomerDocumentsEmailMutation, subscriptionId])

    return (
      <DataRow>
        <Text variant="body2">Documents</Text>
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
          `}
        >
          <Button
            variant="secondary"
            size="small"
            onClick={downloadDocuments}
            isLoading={sendingEmail}
            disabled={sendingEmail}
          >
            Envoyer les documents
          </Button>
        </div>
      </DataRow>
    )
  },
)
