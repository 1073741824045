import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardAddressRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardAddressRow: FunctionComponent<ContractCardAddressRowsProps> = memo(
  function ContractCardAddressRow({ contract }) {
    const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
    return (
      <DataRow>
        <Text variant="body2">Adresse (entreprise)</Text>
        <Text variant="body2">{latestVersion.dimensions.companyAddress}</Text>
      </DataRow>
    )
  },
)
