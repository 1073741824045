import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardOldContractTerminationRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardOldContractTerminationRow: FunctionComponent<ContractCardOldContractTerminationRowsProps> =
  memo(function ContractCardContractNumberRows({ contract }) {
    const latestVersion = checkDefinedAndNotNull(contract.versions.at(-1))
    return (
      <DataRow>
        <Text variant="body2">Contrat d’assurance pro à résilier</Text>
        <Text variant="body2">{latestVersion.dimensions.terminatePreviousContract ? 'Oui' : 'Non'}</Text>
      </DataRow>
    )
  })
