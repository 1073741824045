import styled from '@emotion/styled'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, Chip, spacing, Text } from '@orus.eu/pharaoh'
import { getProtectionStatusAtTime } from '@orus.eu/protection'
import { memo, type FunctionComponent } from 'react'
import { getContractStatusUiProps } from '../../../lib/contract-util'
import { formatDateTime } from '../../../lib/format'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { oneMinute, useCurrentTimestamp } from '../../../lib/use-current-timestamp'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardStatusRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardStatusRow: FunctionComponent<ContractCardStatusRowsProps> = memo(
  function ContractCardStatusRow({ contract }: ContractCardStatusRowsProps): JSX.Element {
    const { type: userType, permissions } = usePermissions()
    const isPlatform = userType === 'platform'

    const navigateToContract = useNavigateTo({
      to: '/bak/contracts/$subscriptionId/status',
      params: { subscriptionId: contract.subscriptionId },
    })
    const currentTimestamp = useCurrentTimestamp(oneMinute)
    const protectionStatusSchedule = getProtectionStatusAtTime(contract.protectionHistory, currentTimestamp)

    const { icon, label, variant, comment, timestamp } = getContractStatusUiProps(protectionStatusSchedule)
    return (
      <DataRow>
        <Text variant="body2">Statut</Text>
        <StatusSpan>
          <Chip size="large" variant={variant} icon={icon}>
            {label}
          </Chip>
          {timestamp ? <Text variant="body2">{formatDateTime(timestamp)}</Text> : null}
        </StatusSpan>
        {comment ? <Text variant="body2">{comment}</Text> : null}
        {isPlatform && permissions.includes('endorsement.create') ? (
          <Button variant="secondary" size="small" onClick={navigateToContract}>
            Modifier le statut
          </Button>
        ) : (
          <></>
        )}
      </DataRow>
    )
  },
)

const StatusSpan = styled.span`
  display: flex;
  gap: ${spacing[50]};
  align-items: center;
`
