import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { formatDateTime } from '../../../lib/format'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardStartRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardStartRow: FunctionComponent<ContractCardStartRowsProps> = memo(function ContractCardStartRows({
  contract,
}) {
  return (
    <DataRow>
      <Text variant="body2">Début</Text>
      <Text variant="body2">{formatDateTime(contract.versions[0].startTimestamp)}</Text>
    </DataRow>
  )
})
