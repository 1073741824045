import { css } from '@emotion/react'
import type { BackofficeContractDescription } from '@orus.eu/backend/src/views/backoffice-contract-view'
import { Button, Text } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { formatDateTime } from '../../../lib/format'
import { useNavigateTo } from '../../../lib/hooks/use-navigate-to-route'
import { usePermissions } from '../../../lib/use-permissions'
import { DataRow } from '../../molecules/backoffice-data-card'

export type ContractCardSignatureRowsProps = {
  contract: BackofficeContractDescription
}

export const ContractCardSignatureRow: FunctionComponent<ContractCardSignatureRowsProps> = memo(
  function ContractCardSignatureRows({ contract }) {
    const { type: userType, permissions } = usePermissions()

    const navigateToContract = useNavigateTo({
      to: '/bak/pending-subscriptions/$subscriptionId/history',
      params: { subscriptionId: contract.subscriptionId },
    })

    return (
      <DataRow>
        <Text variant="body2">Signature</Text>
        <span>
          {userType === 'platform' && permissions.includes('subscription.readHistory') ? (
            <Button variant="secondary" size="small" onClick={navigateToContract}>
              Voir l’historique
            </Button>
          ) : (
            <></>
          )}
        </span>
        <Text
          variant="body2"
          css={css`
            min-width: max-content;
          `}
        >
          {formatDateTime(contract.versions[0].signature.timestamp)}
        </Text>
      </DataRow>
    )
  },
)
